<script lang="ts">
  import type { CourseModel } from '@24hbs/utils';
  import { handleUrlClientSide } from '$lib/utils/assets-utils';
  import { MBAUtils } from '$lib/utils/mba-utils';

  export let course: CourseModel;

  let customLink = MBAUtils.isCourseOfTypeMBA(course) ? `/${MBAUtils.NORMAL_SLUG}` : false;
  let courseLink = `/formazione/${course.slug}`;

  if (customLink) {
    courseLink = customLink;
  }
</script>

<section class="search-popular-card">
  <div class="search-popular-image-wrapper">
    <div class="search-popular-image">
      <!-- Img width = 66px — 1:1 Ratio -->
      <img
        class="image"
        src={handleUrlClientSide(course.previewUrl)}
        alt={course.title}
      />
    </div>
  </div>
  <div class="search-popular-content">
    <div class="search-popular-info">
      <p class="search-popular-type">
        {course.getMainSession().courseTypeFull}
      </p>
      <a
        class="search-popular-link"
        href={courseLink}
      >
        <h2 class="search-popular-title">{course.title}</h2>
      </a>
    </div>
  </div>
</section>

<style type="text/scss" lang="scss" scoped>
  .search-popular-card {
    @apply flex;
    @apply relative;
    @apply w-full max-w-screen-md;

    &:hover {
      & .image {
        @apply transform scale-105;
      }
    }
  }

  .search-popular-image-wrapper {
    @apply flex-shrink-0;
    width: var(--search-popular-item-image-width);
  }

  .search-popular-image {
    @apply overflow-hidden;
  }

  .image {
    @apply object-cover;
    @apply transition-transform duration-500 ease-in-out;
    will-change: transform;
  }

  .search-popular-image-wrapper + .search-popular-content {
    @apply ml-3;
  }

  .search-popular-content {
    @apply overflow-hidden;
    @apply w-full;
    @apply flex flex-col;
    @apply pb-4 md:pb-6;
    @apply border-gray-600 border-b;
  }

  .search-popular-info {
    @apply flex-grow;
  }

  .search-popular-type {
    @apply uppercase tracking-wide font-semibold;
    @apply text-gray-600 text-sm md:text-base;
  }

  .search-popular-type + .search-popular-link {
    @apply mt-2;
  }

  .search-popular-title {
    @apply text-md md:text-lg leading-tight;
  }

  .search-popular-link {
    @apply inline-block;
    @apply text-gray-900 font-medium font-serif;

    &:after {
      content: '';
      @apply absolute inset-0;
      @apply z-10;
    }
  }
</style>
