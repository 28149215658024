import { derived, type Readable, writable, type Writable } from 'svelte/store';
import type { SearchFilterCurrentResults, SearchFilterCurrentSearch, SearchFilterHistory } from './types';

export class SearchFilterStore {
  private static readonly LOCAL_STORAGE_KEY_currentSearch = "currentSearch";
  private static readonly LOCAL_STORAGE_KEY_currentResult = "currentResult";
  private static readonly LOCAL_STORAGE_KEY_history = "history";
  private static readonly ENABLE_LOCAL_STORAGE = false;
  
  private static searchFilterStore: SearchFilterStore|null;
  
  public currentTyped: Writable<string> = <Writable<string>>writable("");
  public suggestedSearches: Writable<string[]> = <Writable<string[]>>writable([]);
  
  public currentSearch: Writable<SearchFilterCurrentSearch> = <Writable<SearchFilterCurrentSearch>>writable({
    query: "",
    category: "",
    lessionMode: "",
    duration: [],
    priceMin: 0,
    priceMax: 30000
  });
  public currentResult: Writable<SearchFilterCurrentResults> = <Writable<SearchFilterCurrentResults>>writable({
    courses: [],
    events: [],
    instructors: []
  });
  public history: Writable<SearchFilterHistory> = <Writable<SearchFilterHistory>>writable({
    searches: []
  });
  public frequent: Writable<string[]> = <Writable<string[]>>writable([]);
  private static loadedFromLocalStorage = false;
  
  public static getInstance(_fetch: {
    (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
    (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
  }): SearchFilterStore{
    
    if(SearchFilterStore.searchFilterStore==null || !SearchFilterStore.loadedFromLocalStorage){
      SearchFilterStore.searchFilterStore = new SearchFilterStore(_fetch);
    }
    return SearchFilterStore.searchFilterStore;
  }
  
  
  private constructor(_fetch: {
    (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
    (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
  }) {
    
    let currentSearchJson:SearchFilterCurrentSearch,currentResultJson:SearchFilterCurrentResults,historyJson:SearchFilterHistory;
    if(SearchFilterStore.ENABLE_LOCAL_STORAGE && typeof localStorage !== 'undefined') {
      SearchFilterStore.loadedFromLocalStorage = true;
      const currentSearchStr: string = localStorage.getItem(SearchFilterStore.LOCAL_STORAGE_KEY_currentSearch);
      try {
        currentSearchJson = <SearchFilterCurrentSearch>JSON.parse(currentSearchStr);
      } catch (e) {
        currentSearchJson = null;
      }
      
      
      const currentResultStr: string = localStorage.getItem(SearchFilterStore.LOCAL_STORAGE_KEY_currentResult);
      try {
        currentResultJson = <SearchFilterCurrentResults>JSON.parse(currentResultStr);
      } catch (e) {
        currentResultJson = null;
      }
      
      const historyStr: string = localStorage.getItem(SearchFilterStore.LOCAL_STORAGE_KEY_history);
      try {
        historyJson = <SearchFilterHistory>JSON.parse(historyStr);
      } catch (e) {
        historyJson = null;
      }
      
      if(currentSearchJson) {
        this.currentSearch = <Writable<SearchFilterCurrentSearch>>writable(currentSearchJson);
      }
      if(currentResultJson) {
        this.currentResult = <Writable<SearchFilterCurrentResults>>writable(currentResultJson);
      }
      
      if(historyJson) {
        
        this.history = <Writable<SearchFilterHistory>>writable(historyJson);
      }
      
      //TODO: this.history Push sometimes to server
      
    }
    
    
    this.currentSearch.subscribe((current: SearchFilterCurrentSearch) => {
      if(current.query && current.query!="") {
        this.currentTyped.set("");
        this.suggestedSearches.set([]);
        
        this.history.update(currentHistory => {
          if (currentHistory && currentHistory.searches && currentHistory.searches.length > 0 && currentHistory.searches.includes(current.query)) {
            currentHistory.searches.splice(currentHistory.searches.indexOf(current.query), 1);
          }
          currentHistory.searches.push(current.query);
          return currentHistory;
        });
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem(SearchFilterStore.LOCAL_STORAGE_KEY_currentSearch, JSON.stringify(current));
        }
      }
    });
    
    this.currentResult.subscribe((current: SearchFilterCurrentResults) => {
      if(typeof localStorage !== 'undefined') {
        localStorage.setItem(SearchFilterStore.LOCAL_STORAGE_KEY_currentResult, JSON.stringify(current));
      }
    });
    
    this.history.subscribe((current: SearchFilterHistory) => {
      if(typeof localStorage !== 'undefined') {
        localStorage.setItem(SearchFilterStore.LOCAL_STORAGE_KEY_history, JSON.stringify(current));
      }
    });
    
    this.currentTyped.subscribe(async (current: string) => {
      if(!current || current=="") {
        this.suggestedSearches.set([]);
        return;
      }
      
      await _fetch("/api/v1/suggestedsearches.json?q="+current)
      .then(res => res.text())
      .then(jsonStr => {
        try {
          const frequentList = <string[]>JSON.parse(jsonStr);
          console.log("List converted: ",frequentList);
          this.suggestedSearches.update(current => {
            console.log("Updating suggestions");
            return frequentList;
          });
        }
        catch (e){
          console.log("Error while reading frequent seaches response.",e);
        }
      });
      await _fetch("/api/v1/frequentsearches.json")
      .then(res => res.text())
      .then(jsonStr => {
        try {
          const frequentList = <string[]>JSON.parse(jsonStr);
          this.frequent.update(current => {
            return frequentList;
          });
        }
        catch (e){
          console.log("Error while reading frequent seaches response.",e);
        }
      });
    });
    
    
  }
  
  public get currentSearchState(): Readable<SearchFilterCurrentSearch> {
    // Use derived to access writable values and export as readonly
    return derived(
      [this.currentSearch],
      ([$currentSearch]) => {
        return $currentSearch;
      }
    )
  }
  
  public get currentResultState(): Readable<SearchFilterCurrentResults> {
    // Use derived to access writable values and export as readonly
    return derived(
      [this.currentResult],
      ([$currentResult]) => {
        return $currentResult;
      }
    )
  }
  
  public get historyState(): Readable<SearchFilterHistory> {
    // Use derived to access writable values and export as readonly
    return derived(
      [this.history],
      ([$history]) => {
        return $history;
      }
    )
  }
}





