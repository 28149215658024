<script lang="ts">
  import { HomePageStore } from '$lib/utils/homepage/store';
  import { SearchQuickLinks} from '$lib/components/molecules/Search'; 
  import { SearchPopularCard } from '$lib/components/organisms/Card';

  const { popularCourses } = HomePageStore.getInstance();
</script>

{#if ($popularCourses && $popularCourses && $popularCourses.length && $popularCourses.length>0)}
  <SearchQuickLinks title="Popolari">
    <div class="w-full space-y-6">
      {#each $popularCourses as course}
        <SearchPopularCard {course} />
      {/each}
    </div>
  </SearchQuickLinks>
{/if}
