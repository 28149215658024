<script lang="ts">
  import { slide } from "svelte/transition"
  import { expoInOut } from "svelte/easing"
  import { SearchFilterStore } from "$lib/utils/searchfilter-store"
	import { Grid } from "$lib/components/organisms/Layout";

  let { currentTyped, suggestedSearches } = SearchFilterStore.getInstance(fetch);

  let listElement: HTMLElement

  const thermHighlighter = (term: string): string => {
    let htmlTerm = term;
    if ($currentTyped){
      const searchStart = term.toLowerCase().indexOf($currentTyped.toLowerCase())

      if (searchStart >= 0) {

        const mark = term.substr(searchStart, $currentTyped.length)

        const textBegin = term.substring(0, searchStart)
        const textEnd = term.substring(searchStart + $currentTyped.length)

        htmlTerm = textBegin + "<mark>" + mark + "</mark>" + textEnd
      }
    }
    return htmlTerm;
  }

</script>

{#if $currentTyped && $currentTyped.length && $suggestedSearches && $suggestedSearches.length>0}
  <div
    class="search-autocompletion"
    in:slide="{{ duration: 500, delay: 300, easing: expoInOut }}"
    out:slide="{{ duration: 500, delay: 300, easing: expoInOut }}"
  >
    <Grid noGrid>
      <div class="autocompletion-list" bind:this="{listElement}">
        {#each $suggestedSearches as searchQuery}
          <a href="/search?q={searchQuery}">{@html thermHighlighter(searchQuery)}</a>
        {/each}
      </div>
    </Grid>
  </div>
{/if}

<style type="text/scss" lang="scss" scoped>
  .search-autocompletion {
    @apply py-6;
    @apply bg-white;
  }

  .autocompletion-list {
    @apply flex flex-col items-start;
  }

  :global(.autocompletion-list > a) {
    @apply relative py-1;

    &:after {
      content: "";
      @apply absolute left-0 bottom-0;
      @apply h-0.5 bg-current w-full bg-opacity-30;
      @apply transform scale-x-0;
      @apply ease-in-out duration-200 origin-right;
      transition-property: transform;
    }

    &:hover:after {
      @apply transform scale-x-100;
      @apply origin-left;
    }
  }

  :global(.autocompletion-list > a > mark) {
    @apply bg-transparent font-semibold;
  }
</style>
