<script lang="ts">
	import { Grid } from "$lib/components/organisms/Layout";

  export let title: string
</script>

<div class="search-quicklinks">
  <Grid noGrid>
    {#if title}
      <p class="subtitle quicklinks-title">{title}</p>
    {/if}
    <div class="quicklinks-list">
      <slot />
    </div>
  </Grid>
</div>

<style type="text/scss" lang="scss" scoped>
  .search-quicklinks {
    @apply flex-1;
    @apply bg-white;
    @apply py-6;
  }

  .quicklinks-title {
    @apply col-start-2 col-span-10;
    @apply mb-5;
    @apply md:text-base;
  }

  .quicklinks-list {
    @apply col-start-2 col-span-10;
    @apply flex flex-col items-start;
  }

  :global(.quicklinks-list > a) {
    @apply relative py-1;

    &:after {
      content: "";
      @apply absolute left-0 bottom-0;
      @apply h-0.5 bg-current w-full bg-opacity-30;
      @apply transform scale-x-0;
      @apply ease-in-out duration-200 origin-right;
      transition-property: transform;
    }

    &:hover:after {
      @apply transform scale-x-100;
      @apply origin-left;
    }
  }

  :global(.quicklinks-list > a + a) {
    @apply mt-3;
  }
</style>
